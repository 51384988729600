/* Container for header */
/* Reset margin and padding for the body to remove space */

  
/* Style for the top-row */
.top-row {
    display: flex;
    justify-content: flex-end; /* Align text to the right */
    align-items: center;
    width: 100%;
    background-color:#eff7ff; /* Adjust the background color as needed */
    padding: 3px; /* Adjust padding as needed */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: add a shadow for visual separation */
    margin: 0; /* Ensure no margin */
  }
  
  /* Additional style for the text inside the top-row */
  .top-row-text {
    margin: 0;
    font-size: 10px; /* Adjust font size as needed */
    color: whitesmoke; /* Adjust text color as needed */
  }
  .top-row-text a {
    color: whitesmoke;
  }
  .top-row-text a:hover {
    color: rgb(195, 193, 169);
  }
  
  /* Ensure nav has no margin or padding */
  nav.navbar {
    margin: 0;
    padding: 0;
  }
  
  /* Optional: If the navbar has default padding, override it */
  .navbar {
    padding-top: 0;
    padding-bottom: 0;
  }


.header-menu {
    display: flex;
    align-items: center;
    padding: 10px;
}

.logos {
    margin-right: 100px;

}


.container-fluid {
    display: flex;
    justify-content: space-between; /* Aligns the logo to the left and the button to the right */
    align-items: center; /* Vertically centers the items */
    padding: 0;
}

.navbar-brand {
    margin-right: auto; /* Push the logo to the left */
}

.main-logo {
    margin-top: 2px;
    width: 50px;
    height: auto; /* Adjust the height as needed */
}

.main-logos {
    margin-top: 5px;
    width: 220px;
}

.logo-text {
    vertical-align: middle;
    font-family: Lato; /* Replace with your chosen font */
    font-size: 32px; /* Adjust as needed */
    font-weight: 700; /* Bold to emphasize the name */
    color: #2d47ae; /* Choose a color that matches your brand */
}

.main-logo a:hover {
    text-decoration: none;
}

.menu {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end; /* Push the menu to the right */
}

.menu-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}


.menu-item {
    position: relative;
}

.menu-item a {
    display: block;
    color: rgb(79, 79, 79);
    padding: 14px 16px;
    text-decoration: none;
}

.menu-item a:hover {
    border-radius: 12%;
    background-color: #f4f4f4;
}

/* Dropdown content styles */
.dropdown-content, .sub-dropdown-content {
    display: none;
    position: absolute;
    background-color: #e9e8e8;
    min-width: 160px;
    z-index: 1;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdown-item {
    padding: 0;
    text-align: left;
}

.dropdown-item a {
    color: rgb(50, 50, 50);
    text-decoration: none;
    display: block;
    padding: 12px 16px;
    background-color: #ffffff;
}

.dropdown-item a:hover {
    border-radius: 0;
}

/* Show the dropdown on hover */
.menu-item.dropdown:hover .dropdown-content,
.dropdown-item.sub-dropdown:hover .sub-dropdown-content {
    display: block;
}


.sub-dropdown-content {
    left: 100%;
    top: 0;
}
.navs {
    text-align: right;
}

/* Navbar Styles */
.navbar-light .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.1);
    
}
.navbar-toggler {
    margin: 0;
    align-self: flex-end; /* Ensures it stays on the right */
}

/* Navbar.css */
.navbar-nav .nav-item:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
  
  .dropdown-menu {
    margin-top: 0;
  }
  

.nav-item {
    color: #132e80;
}

.dark-bg {
}
.border-rad {
    border-radius: 16px;
}
.width-short {
    width: 50%;
}

.centered-text {
    text-align: center;
    align-items: center;
    margin: 0 auto;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Existing styles ... */

/* Add the new-color class style */
.new-color {
    color: blue !important;
}

/* Ensure the links within the new-color list items are also blue */
.new-color a {
    color: blue !important;
}

/* Ensuring dropdown visibility on hover */
.dropdown:hover > .dropdown-menu {
    display: block;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}


.navbar {
    box-shadow: none;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}


.navbar-light .navbar-nav .nav-link {
    color: rgb(79, 79, 79);

}

.navbar-light .navbar-nav .nav-link:hover {
    background-color: #f4f4f4;
    border-radius: 12%;
}

.dropdown-menu .dropdown-item {
    padding: 12px 16px;
    color: rgb(50, 50, 50);
    background-color: #ffffff;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #7bb8d8  !important;
    color: rgb(59, 59, 59) !important;
}

.navbar-dark {
    color:white !important;
}

.navbar-dark a {
    color:rgb(62, 58, 58) !important;
}

.dropdown-submenu a  {
    color:#132e80 !important;
}

.navbar-dark a:hover {
    color:rgb(251, 251, 253) !important;
    background-color: #018edb;
    border-radius: 10%;
}


.nav-item a {
    font-family:Tahoma !important;
    font-size: 16px !important;
    
    }


.dropdown-submenu .dropdown-menu {
    left: 100%;
    top: 0;
    margin-top: -1px;
}

.dropdown-submenu .dropdown-menu nav-item a {
    color:#132e80;
}

.dropdown-submenu .dropdown-menu a {
    color:#132e80;
}


/* Show the dropdown on hover */
.menu-item.dropdown:hover .dropdown-content,
.dropdown-item.sub-dropdown:hover .sub-dropdown-content {
    display: block;
}

/* Ensuring dropdown visibility on hover */
.dropdown:hover > .dropdown-menu {
    display: block;

}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
    
}


    

@media (max-width: 768px) {
    .navbar-collapse {
        position: absolute;
        top: 100%; /* Positions the dropdown directly below the navbar */
        width: 100px; /* Ensures the dropdown takes the full width */
        text-align: left;
        margin-left: -88px;
        background-color: #ffffff; /* Matches navbar background */
        border: 1px solid #ddd; /* Optional: adds a border */
        z-index: 1000; /* Ensures it stays on top */
    }
.main-logos {
    width: 130px;
}
.main-logo {
    width: 30px;
}
    .dropdown-submenu .dropdown-menu {
        left: -100%;
        top: 0;
        margin-top: -1px;
    }
    .navbar-toggler {
        margin: 0;
        text-align: right;
        overflow:auto;
    }
    #hidden {
        visibility:hidden;
    }
}
