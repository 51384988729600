body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "navi";   /*Can be any text*/
  src: local("Lato-Bold"),
    url("./fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";   /*Can be any text*/
  src: local("Inter"),
    url("./fonts/Inter.ttf") format("truetype");
}

@font-face {
  font-family: "Salmaal";   /*Can be any text*/
  src: local("Salmaal"),
    url("./fonts/Salmaal.otf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
