html,
body {
  margin: 0;
  padding: 0;
  font-family: Inter;
}
.container {
  width: 100%;
}


.logo-text {
  font-size: 24px;
  font-weight: bold;
  font-family: Tahoma;
  text-decoration: none;
  vertical-align: top;
}

.caro {
  width: 75%;
  align-self: center;
  margin: 0 auto;
}


.Home {
  width: 100%;
}

.a-paragraph {
  font-size: 18px;
}

.btn-nav {
  border: 1px solid #313131;
}

.header {
  width: 80%;
}

/* Media query for screens with a maximum width of 768 pixels (adjust as needed) */
@media (max-width: 768px) {
  .home {
    width: 100%;
  }
  .h-content {
    margin-left: 0px;
  }
  .footer {
    visibility: hidden;
  }
.container {
  width: 100%;
}
  .caro {
    width: 95%;
  }
  .main-logo {
    width: 30%;
  }
}

.row-container {
  background-color: rgb(246, 246, 246);
  display: flex;
  justify-content: flex-end; /* Align to the right */
  padding: 10px; /* Adjust padding as needed */
}

.link {
  color: rgb(88, 88, 88);
  font-size: 16px;
  text-decoration: none;
  margin-right: 10px; /* Adjust margin between links as needed */
}

.link:last-child {
  margin-right: 0; /* Remove margin for the last link */
}

.my-nav {
}

.nav-bottom {
  border-bottom: 1px solid rgb(220, 219, 219);
}

nav a {
  text-decoration: none;
  color: #333;
  font-family: "Lato", sans-serif !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  padding: 10px;
}

/* Updated styles for dropdown */
/* .dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: relative;
  top: 100%;
  left: 0;
  z-index: 0;
  flex-direction: column; /* Stack child elements vertically */
/* } */

/* Add this to your CSS file */
.overlay-text {
  position: absolute;
  top: 20%;
  left: 20%;
  transform: translate(-50%, -50%);
  color: rgb(5, 63, 48);
  font-size: 24px;
  text-align: center;
}

.centered-text {
  text-align: center;
  margin: 0; /* Remove default margin */
}

.sub-container {
  width: 80%;
  font-size: 24px;
}

.product-container {
  display: flex;
  justify-content: space-around;
}

.para-main {
  font-size: 28px;
}
/* Add this to your CSS file */

.main-description-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px auto;
  max-width: 1000px;
}

.company-image {
  width: 40%;
  border-radius: 8px;
}

.company-description {
  font-size: 16px;
  width: 70%;
}

.company-name {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 15px;
}

.tagline {
  font-size: 1.5rem;
  color: #666;
  background-color: rgb(236, 245, 253);
  padding: 5px;
  margin-bottom: 20px;
}

.description {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 1.6;
  color: #313131;
  margin-bottom: 15px;
  font-size: 22px;
  text-align: justify;
}

.main-content-pic {
  max-width: 600px;
}

.a-border {
  border: 1px solid rgb(230, 229, 229);
}

.para_background {
  background-color: rgb(244, 244, 244);
}

.thumb-description {
  width: 100%;
  color: #313131;
  font-size: 18px;
  font-style: italic;
  padding: 0%;
}

.thumb-name {
  font-style: normal;
  font-weight: bold;
}


.thumbnail {
  max-width: 290px; /* Adjust max height as needed */
  height: auto;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border: 1px solid rgb(192, 191, 191);
}

.thumbnail2 {
  border: 1px solid #d0d0d0;
  width: 100%;
  max-width: 500px;
}
.product-card {
  flex: 1 0 calc(33.33% - 20px); /* 33.33% width with some margin */
  text-align: center;
  text-decoration: none;
  margin: 10px;
  padding: 10px;
  color: #333; /* Adjust text color as needed */
}

.product-row {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Media query for screens with a maximum width of 768 pixels (adjust as needed) */
@media (max-width: 768px) {
  .product-row {
    width: 90%; /* Each card takes up 100% of the product row width on smaller screens */
  }

 

  .product-card {
    flex: 1 0 calc(33.33% - 20px); /* 33.33% width with some margin */
    text-align: center;
    text-decoration: none;
    margin: 10px;
    padding: 10px;
    color: #333; /* Adjust text color as needed */
  }

  .thumbnail {
    max-height: 250px; /* Adjust max height as needed */
    width: 100%;
    height: auto;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    border: 1px solid rgb(192, 191, 191);
  }
  .row::after {
    content: "";
    flex: auto;
  }

  .thumbnail2 {
    border: 1px solid #d0d0d0;
    width:270px;
  }
  .product-container {
    display:block;
  }
  .product-caption {
    display: contents;
    font-weight: bold;
  }

  /* Add media query for smaller screens if needed */
  .product-card {
    flex: 1 0 calc(90% - 20px); /* 50% width with some margin for smaller screens */
  }

  .company-image {
    width: 100%;
    border-radius: 4px;
  }

  .h-center {
    margin-left: 10%;
    align-items: center;
    align-content: center;
  }



  .address
  {
    margin-top: 15px;
  }
}

/* Add this to your CSS file */

.footer {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  background-color: #e0e0e0;
  color: #4b4a4a;
  padding: 5px;
  margin-top: 55px;
  margin-bottom: -16px;
}

.footer-col {
  width: 150px;
}

.footer-bed {
  width: 100%;
  justify-content: space-between;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #575656;
  color: #ececec;
  padding: 4px;
}

.logo-container {
  float: left;
}

.nav-container {
  align-self: right;
}

.quick-links-column,
.follow-us-column {
  margin-left:140px;
  flex: 1; /* Each column takes up 1 part of the available space */
}

.quick-links-column a:link {
  color: #313131;
  text-decoration: none;
}

.quick-links-column {
  vertical-align: top;
  text-decoration: none;
}

.main-logo {
  max-width: 300px;
}

.footer-logo {
  margin-left: 20px;
  width: 40%; /* Adjust the size as needed */
  text-align: center;
}

.dnv-logo {
  width: 19%; /* Adjust the size as needed */
  text-align: left;
  margin-left: 15px;
}
.italics {
  font-style: italic;
}

.bold {
  font-style: bold;
}

.small-text {
  font-size: 10px;
}

.xxs-text {
  font-size: 8px;
}

.medium-text {
  font-size: 14px;
}
.about-us {
  /* Add your main content styles */
}

/* Hero Section Styles */
.hero {
  background-color: #f0f0f0;
  padding: 15px 0;
  text-align: center;
}

.hero h1 {
  font-size: 2.5rem;
  color: #333;
}

.hero p {
  font-size: 1.2rem;
  color: #666;
}

/* Content Container Styles */
.content-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Section Styles (Adjust as needed) */
.about-apex,
.background-insight,
.unwavering-resolve,
.commitment-excellence,
.our-mission,
.our-solution,
.apex-electricals,
.the-facts {
  padding: 10px 0;
}

.content-container h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.btn .btn-light {
  width: 700px;
}

.no-decor {
  text-decoration: none;
  color: inherit;
}
/* Add styling for ContactCard, Quick Links, and SocialIcons components as needed */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05); */
  color: #212529;
  margin-bottom: 4px;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px 15px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #eeeded;
}

header h3 {
  background-color: #eeeded;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdowning {
  position: absolute !important;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 99999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
  width: 150% !important;
}

.dropdowning.show {
  display: block;
}

.dropdowning .dropdown-submenu {
  position: absolute !important;
  width: 125% !important;
  left: 100%;
  top: -7px;
  background-color: #f5f5f6;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.brand {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid gray;
}

.nav-item {
  margin-right: 20px;
}

.nav-link {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
}

.mobile-menu-icon {
  display: none;
}

.float-right {
  margin-left: auto;
}

/* Adjust the container for overall width */
.variant-container {
  display: flex;
  flex-wrap: wrap;
  width: 130% !important;
  margin: 0 auto; /* Center the container */
}

/* Adjust the product description and image container width */
.product-description {
  font-size: 20px;
  flex: 1 1 55%;
  padding: 10px;
}

.image-container {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  gap: 2px; /* Adjust the gap between images if needed */
}

/* Ensure the images take the full width of their container */
.product-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
}

.variant img {
  max-width: 100%;
  height: auto;
}

.custom-list li i {
  margin-right: 10px;
  color: #6a6b6b; /* Set your desired icon color */
}
.custom-list-item {
  font-size: 19px;
  font-style: italic;
  list-style-type: square !important; /* Remove default bullet style */
  margin-left: 1em; /* Adjust the indentation as needed */
  position: relative;
}

.custom-list-item::before {
  color: #4d4d4d; /* Set the color as needed */
  font-size: 1.5em; /* Adjust the size as needed */
  position: absolute;
  left: -1em; /* Adjust the position as needed */
}

/* Ensure the dropdown-menu is hidden by default */
.dropdown-menu {
  display: none;
}

/* Show the dropdown-menu when the parent is hovered over */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

/* Optional: Add some styling to make the dropdown look better */
.nav-item.dropdown .dropdown-menu {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
}

.nav-item.dropdown-submenu {
  position: relative;
}

.nav-item.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0.1rem;
  display: none;
}

.nav-item.dropdown-submenu:hover .dropdown-menu {
  display: block;
}

@media (max-width: 768px) {
  .variant-container {
    flex-direction: column;
    max-width: 100%; /* Ensure it takes full width on smaller screens */
  }

  .product-description, 
  .image-container {
    flex: 1 1 100%;
  }

  .float-child2 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
 
  .navbar {
    flex-direction: column;
  }

  .navbar-container {
    width: 100%;
  }

  .nav-links {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
    cursor: pointer;
    color: #fff;
  }

  .nav-menu {
    display: none;
    width: 100%;
    flex-direction: column;
    background-color: #333;
  }
  header h3 {
    background-color: #eeeded;
    color: #212529;
    padding-bottom: 4px;
  }
  .nav-item {
    text-align: center;
    margin: 0;
  }

  .nav-link {
    padding: 10px;
  }

  .nav-menu.show {
    display: flex;
  }
  header {
    color: #fff;
    padding: 20px 0;
  }

  .header-menu {
    width: 100%;
  }

  img.centered {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
  
header h2 {
  background-color: #132e80;
}
}